import React from "react";
import Header from "../../../components/Landers/Header";
import Banner from "../../../components/Landers/Banner";
import TrustBrands from "../../../components/Landers/TrustBrands";
import AllCheck from "../../../components/Landers/AllCheck";
import GetStarted from "../../../components/Landers/GetStarted";
import SEO from "../../../components/Landers/SEO";
import Testimonials from "../../../components/Landers/Testimonials";
import Footer from "../../../components/Landers/Footer";
import DataInfo from "../../../components/Data";
import styles from "../../../components/Landers/landers.module.scss";
import SectionTemplate from "../../../components/Landers/SectionTemplate";
import FintechCompanies from "../../../components/Landers/FintechCompanies";

const testimonials = [
  {
    name: "Rupasi M",
    company: "Datarynx Pvt. Ltd.",
    p: `SpringVerify is our go-to app for all our background checks.
			I'm in love with its flexibility, time-bound closures,
			and a support team that is always ready to answer our queries 24x7.`,
  },
  {
    name: "Ravindra M",
    company: "Rupeezen",
    p: `I love the simplicity and clean UI of SpringVerify. As the checks are digitized, there's no need for human intervention.
			This has helped us to quicken the onboarding time for our fresh hires.`,
  },
  {
    name: "Maryam J",
    company: "Resolvit Management and Technology Consulting India Pvt. Ltd.",
    p: `SpringVerify is very simple, reliable, and straightforward. The UX is awesome, checks are fast and most importantly all
			my queries are answered promptly without me having to wait for long.`,
  },
];

export default () => (
  <div className={styles.solutions}>
    <SEO
      title="Background Checks for Financial Institutions  | SpringVerify India"
      desc="Screen candidates effortlessly and verify their financial background instantly with SpringVerify India's blockchain powered technology."
      url="https://in.springverify.com/industry/fintech/"
      imgurl="https://assets-sv-in-lander.s3.ap-south-1.amazonaws.com/LP/seo/solution/SV-IN+(Fintech).jpeg"
    />
    <div className={styles.topSection}>
      <Header />
      <Banner
        h1={`Background Screening \nSolutions for Fast-Growing \nFintech Companies`}
        p={`Protect sensitive information of your company by screening your candidate’s history effortlessly.
        SpringVerify’s digital verifications aids top Financial service organizations, Fintech startups, and NBFCs to
        perform streamlined hiring`}
        img="https://assets-sv-in.gumlet.io/LP/fintech/fintech-banner.png"
      />
    </div>
    <TrustBrands />
    <section>
      <SectionTemplate
        img="fintech/fintech-industry.svg"
        h2={`Need for Background \nScreening in Fintech Industry`}
        p={`Employers heavily rely on information-based hiring practices.
        And, That's why it is very important to run thorough background checks in the Financial Services Industry.
        Here’s how they play a vital role:`}
        ul={[
          "Authenticity and accountability of candidates",
          "A measure to prevent potential financial fraud",
          "Protect sensitive information, intellectual property, and customer data",
          "Tighten security and compliance",
        ]}
      />
    </section>

    <AllCheck data={DataInfo.FintechAllCheck} />

    <section>
      <SectionTemplate
        img="fintech/ideal.svg"
        h2={`SpringVerify - Ideal choice for \nBackground Screening`}
        p={`Trusted by top Fin-tech companies across India, here's how SpringVerify helps yours \nto run background verifications smoothly:`}
        ul={[
          "20+ checks under one roof",
          "Multiple candidate verification in an instant",
          "Customized Digital Solutions",
          "Seamless integration with the HRMS",
          "World’s first “Verify on Whatsapp.”",
        ]}
      />
    </section>
    <FintechCompanies data={DataInfo.FintechCompanies} />
    <Testimonials testimonials={testimonials} path="Employment" />
    <GetStarted
      img="fintech/fintech-getStarted.svg"
      h3={`Industry Pioneers in \nFinance Trust SpringVerify \nfor Streamlined Hiring`}
    />
    <Footer />
  </div>
);
