import React from "react";
import styles from "../../components/Landers/landers.module.scss";

export default () => (
  <section className={styles.brands}>
    <h2>Top brands that trust SpringVerify</h2>
    <div className={styles.list}>
      {["cred", "instamojo", "open", "razorpay", "cashfree", "phonepe"].map(
        (e, i) => (
          <img
            key={i}
            data-src={`https://assets-sv-in.gumlet.io/LP/Brands/${e}.svg`}
            alt={e}
          />
        )
      )}
    </div>
  </section>
);
