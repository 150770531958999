import React from "react";
import Styles from "./FintechCompanies.module.scss";

const FintechCompanies = (props) => {
  return (
    <>
      <div className={Styles.FintechCompaniesWrap}>
        <section>
          <div className={Styles.FintechCompaniesHeader}>
            <h2>
              Customized Solutions for <br />
              Fintech Companies
            </h2>
          </div>
          <div className={Styles.FintechCompanies}>
            {props.data.map((data, index) => {
              return (
                <div className={Styles.FCCard} key={index}>
                  <img
                    src={require(`../../../images/LP/fintechCompanies/${data.path}.png`)}
                  />
                  <h4>{data.title}</h4>
                  <p>{data.desc}</p>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </>
  );
};

export default FintechCompanies;
